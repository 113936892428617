import styled, { css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import MobileLogoImg from "-!svg-react-loader!../../../assets/images/Logo/mobileLogo.svg";
import {
  H2,
  H3,
  H4,
  H5,
  H6,
  TEXT_BUTTON_2,
} from "../../../assets/styles/typography";
import { fadeIn, fadeOut, slideToRight } from "../../../utils/animations";
import { device } from "../../../utils/breakpoints";

interface PageProps {
  animation?: string;
}

interface ImageProps {
  letter?: number;
  fluid: FluidObject | FluidObject[];
}

export const TransitionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-template-rows: repeat(5, auto);
  z-index: 1000;
  pointer-events: none;

  .whiteBlock {
    animation: ${(props: PageProps) =>
      props.animation
        ? css`
            ${fadeIn} 300ms linear
          `
        : css`
            ${slideToRight} 300ms ease-in
          `};
    animation-fill-mode: forwards;
    animation-delay: ${(props: PageProps) => !props.animation && "0.7s"};

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(11),
    &:nth-child(14),
    &:nth-child(19) {
      animation-delay: ${(props: PageProps) => !props.animation && "0.1s"};
    }

    &:nth-child(0),
    &:nth-child(1),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(13),
    &:nth-child(16) {
      animation-delay: ${(props: PageProps) => !props.animation && "0.4s"};
    }
  }
`;

export const TransitionBlock = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  margin-left: auto;
  background: #ffffff;
  z-index: 1;
`;

export const PageWrapperMobile = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  padding: 112px 24px 32px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #013cff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
  overflow-x: hidden;
`;

export const MobileNavigationBar = styled.div`
  height: 72px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 16px 4px rgba(29, 29, 27, 0.04);
`;

export const NavigationBar = styled.div`
  height: 72px;
  position: fixed;
  top: 0;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 16px 4px rgba(29, 29, 27, 0.04);
`;

export const LogoMobile = styled(MobileLogoImg)`
  width: 110px;
  height: 24px;

  @media ${device.tablet}, ${device.tabletLandscape}, ${device.computer} {
    display: none;
  }
`;

export const TitleMobile = styled.h1`
  ${H4}
  margin: 0;
  color: #ffffff;
`;

export const LinkWrapperMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  height: 72px;
`;

export const LinkMobile = styled.div`
  ${TEXT_BUTTON_2}
  padding: 16px 24px;
  white-space: pre-line;
  text-transform: uppercase;
  color: #ff7500;
  text-align: center;

  a,
  a:visited,
  a:link {
    color: #ff7500;
    text-decoration: none;
  }
`;

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  color: #151515;
`;

export const PageWrapperDesktop = styled.div`
  position: relative;
  width: 100%;
  padding: 88px 120px 0 88px;
  display: flex;
  flex-direction: column;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.tablet}, ${device.tabletLandscape} {
    padding: 88px 120px 80px 88px;
  }

  @media ${device.computer} {
    padding: 88px 120px 120px 120px;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(12, 64px);
    grid-column-gap: 32px;
  }

  @media ${device.desktop} {
    padding: 128px 120px 120px 120px;
    grid-template-columns: repeat(12, 110px);
  } ;
`;

export const Title = styled.div`
  ${H5}
  width: 70%;
  margin: 0;
  color: #151515;
  white-space: pre-line;

  span:first-child {
    color: #013cff;
  }

  @media ${device.computer} {
    ${H3}
    width: auto;
    grid-column: 1 / 11;
  }

  @media ${device.desktop} {
    grid-column: 1 / 10;
    ${H2}
  } ;
`;

export const HorizontalDivider = styled.hr`
  width: 144px;
  min-height: 5px;
  margin: 42px 0;
  background: #013cff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin: 40px 0 72px 0;
  }

  @media ${device.computer} {
    display: none;
  } ;
`;

export const OffersLink = styled.span`
  ${H6}
  text-transform: uppercase;
  text-decoration: none;
  color: #013cff;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin-top: 64px;
  }

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media ${device.computer} {
    position: absolute;
    bottom: 120px;
    left: 120px;
    margin-top: 0;
  }

  @media ${device.desktop} {
    bottom: 120px;
  } ;
`;

export const InternshipsLink = styled.span`
  ${H6}
  text-transform: uppercase;
  text-decoration: none;
  color: #013cff;
  @media ${device.tablet}, ${device.tabletLandscape} {
    margin-top: 20px;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  @media ${device.computer} {
    position: absolute;
    bottom: 120px;
    right: 280px;
    margin-top: 0;
  }
  @media ${device.desktop} {
    bottom: 120px;
    right: 300px;
  } ;
`;

export const LettersWrapper = styled.div`
  width: 64%;
  margin-top: 100px;
  margin-left: auto;
  padding-right: 64px;
  display: grid;
  align-items: end;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, auto);

  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 100%;
    margin: 0;
  }

  @media ${device.computer} {
    width: 108%;
    grid-column: 5 / 11;
    padding: 0;
    margin-top: 50px;
  }

  @media ${device.desktop} {
    width: 115%;
    grid-column: 6 / 11;
  } ;
`;

export const Image = styled(Img)`
  display: block;
  width: calc(100% - 22px);
  height: auto;
  margin: 22px;
  grid-row: ${(props: ImageProps) => (props.letter ? 1 : 2)};
  ${(props: ImageProps) => !props.letter && `max-height: 50px;`};

  &:nth-of-type(3) {
    margin-top: auto;
  }

  img {
    padding: ${(props: ImageProps) => (props.letter ? 0 : "0 2vw")};
    object-fit: ${(props: ImageProps) =>
      props.letter ? "contain" : "scale-down"} !important;
  }

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin: 8px;
    margin-top: ${(props: ImageProps) => !props.letter && "32px"};
  }

  @media ${device.computer} {
    bottom: 30px;
  }

  @media ${device.desktop} {
    bottom: 60px;
  } ;
`;
