import React, { useState, useEffect, useRef } from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { isMobileOnly } from "react-device-detect";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import {
  PageWrapperMobile,
  MobileNavigationBar,
  NavigationBar,
  LogoMobile,
  TitleMobile,
  LinkWrapperMobile,
  LinkMobile,
  TransitionWrapper,
  TransitionBlock,
  PageWrapper,
  PageWrapperDesktop,
  Title,
  HorizontalDivider,
  OffersLink,
  InternshipsLink,
  LettersWrapper,
  Image,
} from "./style";
import Navigation, { NavigationRefMethods } from "../../common/Navigation";
import Scroll, { PageChange, ScrollRefMethods } from "../../common/Scroll";
import { LocationProp, PageThemeType } from "../../../utils/types";
import { device } from "../../../utils/breakpoints";
import { fixConjunctions } from "../../../utils/dropTheWord";
import { FluidImageType } from "../../../utils/types";
import { PageTitle } from "../../common/PageTitle";

import loadable from "@loadable/component";
import Loader from "../../common/Loader";

const loadableOptions = {
  fallback: <Loader fullPage width={100} height={100} />,
};
const AboutUsMobile = loadable(
  () => import("./components/AboutUs/Mobile"),
  loadableOptions
);
const RecruitmentMobile = loadable(
  () => import("./components/EmployeeRecruitment/Mobile"),
  loadableOptions
);
const InternshipsMobile = loadable(
  () => import("./components/Recruitment/Mobile"),
  loadableOptions
);
const WhatWeOfferMobile = loadable(
  () => import("./components/WhatWeOffer/Mobile"),
  loadableOptions
);
const CurrentOffersMobile = loadable(
  () => import("./components/CurrentOffers/Mobile"),
  loadableOptions
);
const JoinOurTeamMobile = loadable(
  () => import("./components/JoinOurTeam/Mobile"),
  loadableOptions
);
const AboutUsDesktop = loadable(
  () => import("./components/AboutUs/Desktop"),
  loadableOptions
);
const RecruitmentDesktop = loadable(
  () => import("./components/EmployeeRecruitment/Desktop"),
  loadableOptions
);
const InternshipsDesktop = loadable(
  () => import("./components/Recruitment/Desktop"),
  loadableOptions
);
const WhatWeOfferDesktop = loadable(
  () => import("./components/WhatWeOffer/Desktop"),
  loadableOptions
);
const CurrentOffersDesktop = loadable(
  () => import("./components/CurrentOffers/Desktop"),
  loadableOptions
);
const JoinOurTeamDesktop = loadable(
  () => import("./components/JoinOurTeam/Desktop"),
  loadableOptions
);

const pageThemes = [
  "white",
  "blue",
  "black",
  "black",
  "white",
  "black",
  "white",
] as PageThemeType[];
const navigationThemes = [
  "default",
  "white",
  "white",
  "default",
  "white",
  "blue",
] as PageThemeType[];
const mobileNavigationThemes = ["black"] as PageThemeType[];

const transitionBlocks = [...Array(20)].map((_, index) => (
  <TransitionBlock key={index} className="whiteBlock" />
));

const CareerPage: React.FC<LocationProp> = ({ location, goToPage }) => {
  const [, setLoading] = useState<boolean>(true);
  const [isModalOpen, setModalState] = useState<boolean>(false);
  const [pageChange, setPageChange] = useState<PageChange>();
  const [isMobile, setIsMobile] = useState<boolean>(isMobileOnly);
  const [page, setPage] = useState<number>(0);

  const navRef = useRef<NavigationRefMethods>(null);
  const scrollRef = useRef<ScrollRefMethods>(null);
  const lettersData = useStaticQuery(lettersQuery);
  const isSmallWindow = useMediaQuery({ query: device.mobile });
  const { t } = useTranslation("career");

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setIsMobile(isMobileOnly);
  }, [isMobileOnly]);

  const goToOffers = () => {
    scrollRef?.current?.goToPage && scrollRef?.current?.goToPage(4);
  };

  const goToInternships = () => {
    scrollRef?.current?.goToPage && scrollRef?.current?.goToPage(3);
  };

  const goToMainPage = () => {
    setPage(0);
    navigate("/career");
  };

  return isMobile || isSmallWindow ? (
    <>
      {/* <NavigationBar>
        <LogoMobile onClick={goToMainPage} /> */}
      {/* <MobileNavigationBar /> */}
      <Navigation
        ref={navRef}
        isModalOpen={isModalOpen}
        setModalState={setModalState}
        setPageChange={setPageChange}
        navigationThemes={mobileNavigationThemes}
        location={location}
        theme="light"
        partial={0.5}
        setPage={setPage}
      />
      {/* </NavigationBar> */}
      <Scroll
        ref={scrollRef}
        isModalOpen={isModalOpen}
        pageThemes={pageThemes}
        location={location}
        pageChange={pageChange}
        setPageChange={setPageChange}
        transitionTimeoutMS={600}
        page={page}
        setPage={setPage}
      >
        <PageWrapper>
          <PageTitle subtitle={t(`career`)} />
          <TransitionWrapper animation={pageChange}>
            {transitionBlocks}
          </TransitionWrapper>

          <>
            <PageTitle subtitle={fixConjunctions(t(`OKE_page_title`))} />
            <PageWrapperMobile animation={pageChange}>
              <TitleMobile>{fixConjunctions(t(`OKE_title`))}</TitleMobile>
              <LinkWrapperMobile>
                <LinkMobile onClick={goToOffers}>
                  {fixConjunctions(t(`aboutUs_goToOffers`))}
                </LinkMobile>
              </LinkWrapperMobile>
            </PageWrapperMobile>
          </>
        </PageWrapper>
        <AboutUsMobile pageChange={pageChange} />
        <RecruitmentMobile pageChange={pageChange} />
        <InternshipsMobile pageChange={pageChange} />
        <CurrentOffersMobile
          pageChange={pageChange}
          setPageChange={setPageChange}
        />
        <WhatWeOfferMobile
          pageChange={pageChange}
          goToPage={scrollRef?.current?.goToPage}
          blockScroll={scrollRef?.current?.blockScroll}
          setOpen={setModalState}
        />
        <JoinOurTeamMobile pageChange={pageChange} />
      </Scroll>
    </>
  ) : (
    <>
      <Navigation
        ref={navRef}
        isModalOpen={isModalOpen}
        setModalState={setModalState}
        setPageChange={setPageChange}
        navigationThemes={navigationThemes}
        location={location}
        theme="light"
        setPage={setPage}
      />
      <Scroll
        ref={scrollRef}
        isModalOpen={isModalOpen}
        pageThemes={pageThemes}
        location={location}
        pageChange={pageChange}
        setPageChange={setPageChange}
        transitionTimeoutMS={600}
        page={page}
        setPage={setPage}
      >
        <PageWrapper>
          <PageTitle subtitle={t(`career`)} />
          <TransitionWrapper animation={pageChange}>
            {transitionBlocks}
          </TransitionWrapper>

          <>
            <PageTitle subtitle={fixConjunctions(t(`OKE_page_title`))} />
            <PageWrapperDesktop animation={pageChange}>
              <Title>
                <span>{fixConjunctions(t(`OKE_title1`))}</span>
                <span>{fixConjunctions(t(`OKE_title2`))}</span>
              </Title>
              <HorizontalDivider />
              <LettersWrapper>
                {lettersData.letters.nodes.map(
                  (image: FluidImageType, index: number) => (
                    <React.Fragment key={index}>
                      <Image letter={1} fluid={image.childImageSharp.fluid} />
                    </React.Fragment>
                  )
                )}
              </LettersWrapper>
              <OffersLink onClick={goToOffers}>
                {t(`aboutUs_goToOffers`)}
              </OffersLink>
              <InternshipsLink onClick={goToInternships}>
                {t(`aboutUs_goToInternships`)}
              </InternshipsLink>
            </PageWrapperDesktop>
          </>
        </PageWrapper>
        <AboutUsDesktop
          pageChange={pageChange}
          goToPage={scrollRef?.current?.goToPage}
          navPageThemeChange={navRef?.current?.setPageTheme}
          scrollPageThemeChange={scrollRef?.current?.setPageTheme}
        />
        <RecruitmentDesktop pageChange={pageChange} />
        <InternshipsDesktop pageChange={pageChange} />
        <CurrentOffersDesktop
          pageChange={pageChange}
          setPageChange={setPageChange}
        />
        <WhatWeOfferDesktop
          pageChange={pageChange}
          goToPage={scrollRef?.current?.goToPage}
          blockScroll={scrollRef?.current?.blockScroll}
          setOpen={setModalState}
        />
        <JoinOurTeamDesktop
          pageChange={pageChange}
          showFooterOnTop={scrollRef?.current?.showFooterOnTop}
        />
      </Scroll>
    </>
  );
};

const lettersQuery = graphql`
  {
    letters: allFile(
      filter: { relativeDirectory: { eq: "okeLetters" } }
      sort: { order: ASC, fields: [name] }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default CareerPage;
