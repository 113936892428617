import React, { useEffect } from "react";

import { Wrapper } from "../assets/styles/pageStyle";
import CareerPage from "../components/pages/Career";
import { LocationProp } from "../utils/types";

const Career: React.FC<LocationProp> = ({ location }) => {
  const onResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    onResize();
    window && window.addEventListener('resize', onResize);
    return () => {
      window && window.removeEventListener('resize', onResize);
    }
  }, []);

  return (
    <Wrapper>
      <CareerPage location={location} />
    </Wrapper>
  );
};

export default Career;
